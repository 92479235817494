<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitudes Nacionales</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Asignación Solicitudes</li>
                  <li class="breadcrumb-item active">Formulario</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <!-- /.card-header -->
              <div class="card-body">
                <div class="row">
                  <!-- Tarjeta de solicitudes -->
                  <div class="col-md-12">
                    <div class="card card-outline card-navy">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <h6>
                            <i class="fas fa-chalkboard-teacher"></i>
                            Solicitudes
                          </h6>
                        </h3>
                        <div class="card-tools">
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body table-responsive">
                        <table class="table table-bordered table-striped table-hover table-sm">
                          <thead class="thead-dark">
                            <tr>
                              <th class="text-center">#</th>
                              <th class="text-center">Solicitante</th>
                              <th class="text-center"># Pasajeros</th>
                              <th class="text-center">Origen</th>
                              <th class="text-center">Destino</th>
                              <th class="text-center">Fecha Inicio</th>
                              <th class="text-center">Fecha Fin</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(sol, index) in solicitudes"
                              :key="index"
                            >
                              <td class="text-center">{{ sol.solicitud_id }}</td>
                              <td>{{ sol.solicitante }}</td>
                              <td class="text-center">{{ sol.cant_pasajeros }}</td>
                              <td>
                                <div class="custom-control custom-radio">
                                  <input
                                    class="custom-control-input"
                                    type="radio"
                                    :id="`origen${index}`"
                                    name="origen"
                                    @change="validaSolicitudOrigen(sol.solicitud_array)"
                                  />
                                  <label
                                    :for="`origen${index}`"
                                    class="custom-control-label"
                                  >
                                    {{ sol.solicitud_array.direccion_origen }}
                                  </label>
                                </div>
                              </td>
                              <td>
                                <div class="custom-control custom-radio">
                                  <input
                                    class="custom-control-input"
                                    type="radio"
                                    :id="`destino${index}`"
                                    name="destino"
                                    @change="validaSolicitudDestino(sol.solicitud_array)"
                                  />
                                  <label
                                    :for="`destino${index}`"
                                    class="custom-control-label"
                                  >
                                    {{ sol.solicitud_array.direccion_destino }}
                                  </label>
                                </div>
                              </td>
                              <td class="text-center">{{ sol.fecha_ini }}</td>
                              <td class="text-center">{{ sol.fecha_fin }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- Tarjeta de solicitante - transporte -->
                  <div class="col-md-6">
                    <!-- Tarjeta de transporte -->
                    <div class="card card-outline card-navy">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <h6>
                            <i class="fas fa-bus"></i>
                            Datos de Transporte
                          </h6>
                        </h3>
                        <div class="card-tools">
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="form-group col-md-12">
                            <label for="tipo_vehiculo">Tipo Vehículo</label>
                            <v-select
                              :class="[
                                $v.form.tipo_vehiculo.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="form.tipo_vehiculo"
                              placeholder="Tipo Vehiculo"
                              label="nombre"
                              :options="listasForms.tipo_vehiculos"
                              class="form-control form-control-sm p-0"
                            ></v-select>
                          </div>
                          <div class="form-group col-md-12">
                            <label for="fecha_ini">Fecha y Hora Inicial</label>
                            <div class="row">
                              <div class="col-md-7">
                                <input
                                  type="date"
                                  v-model="form.fecha_ini"
                                  style="font-size: 13px"
                                  class="form-control form-control-sm"
                                  :class="
                                    $v.form.fecha_ini.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  disabled
                                />
                              </div>
                              <div class="col-md-5">
                                <input
                                  v-model="form.hora_ini"
                                  type="text"
                                  class="form-control form-control-sm"
                                  style="font-size: 13px"
                                  v-mask="{
                                    mask: '99:99:99',
                                    hourFormat: '24',
                                  }"
                                  :class="
                                    $v.form.hora_ini.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-md-12">
                            <label for="fecha_fin">Fecha y Hora final</label>
                            <div class="row">
                              <div class="col-md-7">
                                <input
                                  type="date"
                                  v-model="form.fecha_fin"
                                  style="font-size: 13px"
                                  class="form-control form-control-sm"
                                  :class="
                                    $v.form.fecha_fin.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  @input="validaFechasForm"
                                  disabled
                                />
                              </div>
                              <div class="col-md-5">
                                <input
                                  v-model="form.hora_fin"
                                  type="text"
                                  class="form-control form-control-sm"
                                  style="font-size: 13px"
                                  v-mask="{
                                    mask: '99:99:99',
                                    hourFormat: '24',
                                  }"
                                  :class="
                                    $v.form.hora_fin.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  @blur="validaFechasForm"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Tarjeta de locación -->
                  <div class="col-md-6">
                    <div class="card card-outline card-navy">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <h6><i class="fas fa-street-view"></i> Locación</h6>
                        </h3>
                        <div class="card-tools">
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 form-group">
                            <label for="origen">Origen</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="form.direccionOrigen"
                              :class="
                                $v.form.direccionOrigen.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                          <div class="col-md-12 form-group">
                            <label for="destino">Destino</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="form.direccionDestino"
                              :class="
                                $v.form.direccionDestino.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                          <div class="col-md-12 form-group">
                            <label for="ruta">Ruta</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="form.ruta"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Tarjeta de pasajeros -->
                  <div class="col-md-7">
                    <div class="card card-outline card-navy">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <h6>
                            <i class="fas fa-users"></i>
                            Datos Pasajeros
                          </h6>
                        </h3>
                        <div class="btn-group float-right">
                          <div class="card-tools">
                            <button
                              type="button"
                              class="btn btn-tool"
                              data-card-widget="collapse"
                              data-toggle="tooltip"
                              title="Collapse"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card-body table-responsive pl-2 pr-2">
                        <div class="row">
                          <div class="col-md-12">
                            <table
                              id="tabla-pasajeros"
                              class="table table-bordered table-striped table-hover table-sm mb-0">
                              <thead>
                                <tr>
                                  <th class="text-center">Nombre</th>
                                  <th class="text-center">N° Documento</th>
                                  <th class="text-center">Correo</th>
                                  <th class="text-center">Teléfono</th>
                                  <th class="text-center">Empresa</th>
                                  <!-- <th class="text-center">Acciones</th> -->
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(pasajero, index) in pasajeros"
                                  :key="pasajero.id"
                                >
                                  <td class="text-nowrap">
                                    <span v-if="formActualizarPa && idActualizarPa == index">
                                      <!-- Formulario para nombres -->
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="nombre"
                                        v-model="formPa2.nombre"
                                        :class="
                                          $v.formPa2.nombre.$invalid
                                            ? 'is-invalid'
                                            : 'is-valid'
                                        "
                                      />
                                    </span>
                                    <span v-else>
                                      <!-- Dato nombre -->
                                      <span
                                        v-if="pasajero.funcionario_id"
                                        class="badge badge-info"
                                        >Func.</span
                                      >
                                      {{ pasajero.nombres }}
                                    </span>
                                  </td>
                                  <td>
                                    <span v-if="formActualizarPa && idActualizarPa == index"
                                    >
                                      <!-- Formulario para numero documento -->
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="numero_documento"
                                        v-model="formPa2.numero_documento"
                                        :class="
                                          $v.formPa2.numero_documento.$invalid
                                            ? 'is-invalid'
                                            : 'is-valid'
                                        "
                                      />
                                    </span>
                                    <span v-else>
                                      <!-- Dato numero_documento -->
                                      {{ pasajero.numero_documento }}
                                    </span>
                                  </td>
                                  <td>
                                    <span v-if="formActualizarPa && idActualizarPa == index"
                                    >
                                      <!-- Formulario para correo -->
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="correo"
                                        v-model="formPa2.correo"
                                        :class="
                                          $v.formPa2.correo.$invalid
                                            ? 'is-invalid'
                                            : 'is-valid'
                                        "
                                      />
                                    </span>
                                    <span v-else>
                                      <!-- Dato correo -->
                                      {{ pasajero.correo }}
                                    </span>
                                  </td>
                                  <td>
                                    <span v-if="formActualizarPa && idActualizarPa == index">
                                      <!-- Formulario para telefono -->
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="telefono"
                                        v-model="formPa2.telefono"
                                        :class="
                                          $v.formPa2.telefono.$invalid
                                            ? 'is-invalid'
                                            : 'is-valid'
                                        "
                                      />
                                    </span>
                                    <span v-else>
                                      <!-- Dato telefono -->
                                      {{ pasajero.telefono }}
                                    </span>
                                  </td>
                                  <td>
                                    <span v-if="formActualizarPa && idActualizarPa == index">
                                      <!-- Formulario para empresa -->
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="empresa"
                                        v-model="formPa2.empresa"
                                        :class="
                                          $v.formPa2.empresa.$invalid
                                            ? 'is-invalid'
                                            : 'is-valid'
                                        "
                                      />
                                    </span>
                                    <span v-else>
                                      <!-- Dato empresa -->
                                      {{ pasajero.empresa_id }}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Tarjeta de datos CECO-AFE -->
                  <div class="col-md-5">
                    <div class="card card-outline card-navy">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <h6><i class="fas fa-dollar-sign"></i> Datos Ceco y Afe</h6>
                        </h3>
                        <div class="card-tools">
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body table-responsive pl-2 pr-2">
                        <div class="row">
                          <div
                            class="col-md-12 mb-0 text-center"
                            :class="
                              total_porcentaje == 100
                                ? 'text-success'
                                : total_porcentaje < 100
                                ? 'text-muted'
                                : 'text-danger'
                            "
                          >
                            <h4>Porcentaje Total: {{ total_porcentaje }}%</h4>
                          </div>
                          <div class="col-md-12">
                            <table id="tabla-pasajeros"
                              class="table table-bordered table-striped table-hover table-sm mb-0">
                              <thead>
                                <tr>
                                  <th class="text-center">Responsable</th>
                                  <th class="text-center">Tipo Presupuesto</th>
                                  <th class="text-center">Porcentaje</th>
                                  <th class="text-center">Número</th>
                                  <!-- <th class="text-center">Acciones</th> -->
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(cecoAfe, index) in cecosAfes"
                                  :key="cecoAfe.id"
                                >
                                  <td>
                                    <span v-if="formActualizarCA && idActualizarCA == index">
                                      <!-- Formulario para nombre -->
                                      <v-select
                                        :class="[
                                          $v.formCeAf2.funcionario.$invalid
                                            ? 'is-invalid'
                                            : 'is-valid',
                                          $store.getters.getDarkMode
                                            ? 'dark-vselect'
                                            : '',
                                        ]"
                                        v-model="formCeAf2.funcionario"
                                        placeholder="Responsable"
                                        label="nombres"
                                        :options="listasForms.respon"
                                        class="form-control form-control-sm p-0"
                                      ></v-select>
                                    </span>
                                    <span v-else>
                                      <!-- Dato nombre -->
                                      {{ cecoAfe.funcionario ? cecoAfe.funcionario.nombres : "" }}
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    <div v-if="cecoAfe.tipo_presupuesto == 1">
                                      <h5><span class="badge badge-warning">Afe</span></h5>
                                    </div>
                                    <div v-else-if="cecoAfe.tipo_presupuesto == 2">
                                      <h5><span class="badge badge-success">Ceco</span></h5>
                                    </div>
                                  </td>
                                  <td>
                                    <span v-if="formActualizarCA && idActualizarCA == index">
                                      <!-- Formulario para porcentaje -->
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="porcentaje"
                                        v-model="formCeAf2.porcentaje"
                                        :class="
                                          $v.formCeAf2.porcentaje.$invalid
                                            ? 'is-invalid'
                                            : 'is-valid'
                                        "
                                      />
                                    </span>
                                    <span v-else>
                                      <!-- Dato porcentaje -->
                                      {{ cecoAfe.porcentaje }}
                                    </span>
                                  </td>
                                  <td>
                                    <span v-if="formActualizarCA && idActualizarCA == index">
                                      <!-- Formulario para numero documento -->
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="numero"
                                        v-model="formCeAf2.numero"
                                        :class="
                                          $v.formCeAf2.numero.$invalid
                                            ? 'is-invalid'
                                            : 'is-valid'
                                        "
                                      />
                                    </span>
                                    <span v-else>
                                      <!-- Dato numero_documento -->
                                      {{ cecoAfe.numero }}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Tarjeta de Itinerario -->
                  <SolicitudesNacionalesItinerarios
                    ref="SolicitudesNacionalesItinerarios"
                  ></SolicitudesNacionalesItinerarios>
                </div>
              </div>
              <div class="card-footer">
                <button
                  class="btn bg-primary col-md-1"
                  v-show="
                    !$v.form.$invalid &&
                      this.pasajeros.length > 0 &&
                      this.total_porcentaje == 100 &&
                      this.flagItin
                  "
                  @click="save()"
                >
                  <i class="fas fa-paper-plane"></i><br />Guardar
                </button>
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i><br />Volver
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import SolicitudesNacionalesItinerarios from "./SolicitudesNacionalesItinerarios";

export default {
  name: "SolicitudesNacionalesForm",
  components: {
    Loading,
    vSelect,
    SolicitudesNacionalesItinerarios,
  },
  data() {
    return {
      origen_agrupamiento: null,
      array_solicitudes: [],
      total_porcentaje: 0,
      solicitudes: [],
      pasajeros: [],
      users: [],
      capacidad_pasajeros: 0,
      tipoVehiculo: null,
      responsable: [],
      preCecosAfes: [],
      cecosAfes: [],
      tipo_vehiculo: [],
      formActualizarCA: false,
      idActualizarCA: -1,
      formActualizarPa: false,
      ruta_origen: null,
      ruta_destino: null,
      idActualizarPa: -1,
      formCeAf: {
        funcionario: null,
        tipo_presupuesto: null,
        porcentaje: null,
        numero: null,
      },
      formCeAf2: {
        funcionario: null,
        tipo_presupuesto: null,
        porcentaje: null,
        numero: null,
      },
      formPa: {
        nombre: null,
        numero_documento: null,
        correo: null,
        telefono: null,
        empresa_id: null,
      },
      formPa2: {
        nombre: null,
        numero_documento: null,
        correo: null,
        telefono: null,
        empresa_id: null,
      },
      form: {
        id: null,
        origen_id: null,
        solicitudes_has_users: [],
        destino_id: null,
        nombre: "",
        identificacion: null,
        celular: null,
        area: "",
        tipo_vehiculo: null,
        fecha_ini: null,
        hora_ini: null,
        fecha_fin: null,
        hora_fin: null,
        sitio: null,
        direccionOrigen: null,
        direccionDestino: null,
        ruta: "",
        motivo: "",
      },
      form1: {},
      itinerarios: [],
      flagItin: false,
      user: [],
      listasForms: {
        tipo_vehiculos: [],
        gerente: [],
        transportadoras: [],
        respon: [],
        tipos_sevicios: [],
        cecos_afes: [],
        rutas: [],
        tipo_operacion: [],
        areas: [],
        turnos: [],
      },
      cargando: false,
    };
  },
  validations: {
    form: {
      tipo_vehiculo: {
        required,
      },
      fecha_ini: {
        required,
      },
      hora_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
      hora_fin: {
        required,
      },
      direccionOrigen: {
        required,
      },
      direccionDestino: {
        required,
      },
    },

    formCeAf: {
      funcionario: {
        required,
      },
      tipo_presupuesto: {
        required,
      },
      porcentaje: {
        required,
      },
      numero: {
        required,
      },
    },

    formCeAf2: {
      funcionario: {
        required,
      },
      tipo_presupuesto: {
        required,
      },
      porcentaje: {
        required,
      },
      numero: {
        required,
      },
    },

    formPa: {
      nombre: {
        required,
      },
      numero_documento: {
        required,
      },
      correo: {
        required,
      },
      telefono: {
        required,
      },
      empresa_id: {
        required,
      },
    },

    formPa2: {
      nombre: {
        required,
      },
      numero_documento: {
        required,
      },
      correo: {
        required,
      },
      telefono: {
        required,
      },
      empresa_id: {
        required,
      },
    },
  },

  methods: {
    async init() {
      this.cargando = true;
      this.form1 = this.$route.params.data_edit;
      await this.getDatos();
      await this.getMotivo();
      this.cargando = false;
    },

    async getDatos() {
      // Si solo viene una solicitud se llenan los datos del solicitante
      this.llenarSolicitante();

      // Se llenan los datos del formulario de tranporte
      this.llenarTransporte();

      // se establece el limite de pasajeros de la solicitud y los datos del tipo de vehículo en el formulario
      this.capacidad_pasajeros = this.form1[0].tipo_vehiculo.cantidad_pasajeros;
      this.tipoVehiculo = this.form1[0].tipo_vehiculo.nombre;

      this.form1.forEach((solicitud) => {
        this.array_solicitudes.push(solicitud.id);
        // Se toman los datos para la tabla de solicitudes
        let name = "";
        if (solicitud.users.length > 0) {
          name = solicitud.users[0].name;
          this.form.solicitudes_has_users.push(solicitud.users[0].id);
        }

        this.solicitudes.push({
          solicitud_array: solicitud,
          solicitud_id: solicitud.id,
          solicitante: name,
          cant_pasajeros: solicitud.pasajeros.length,
          origen: `${solicitud.direccion_origen}`,
          destino: `${solicitud.direccion_destino}`,
          fecha_ini: solicitud.fecha_hora_inicio_servicio,
          fecha_fin: solicitud.fecha_hora_fin_servicio,
        });

        // Se buscan los pasajeros de la solicitud
        this.llenarpasajero(solicitud.pasajeros);

        // Se buscan los CECO AFES de las solicitudes
        this.llenarCecoAfe(solicitud.ceco_afe);

        // Se buscan los usuarios solicitentes de las solicitudes
        this.llenarUsuario(solicitud.users, solicitud.pasajeros);
      });

      // Se calculan los CECO AFES resultantes
      await this.calcularCecoAfe();

      // Se llenan los datos del formulario del itinerario
      await this.getFormItnerarioInicial();
    },

    llenarSolicitante() {
      if (this.form1.length == 1) {
        this.form.id = this.form1[0].id;
        if (this.form1[0].users.length > 0) {
          let funcionario = "";
          if (
            this.form1[0].users[0].funcionario &&
            this.form1[0].users[0].funcionario.length > 0
          ) {
            funcionario = this.form1[0].users[0].funcionario[0].nArea;
          }
          this.form.user_id = this.form1[0].users[0].id;
          this.form.nombre = this.form1[0].users[0].name;
          this.form.identificacion = this.form1[0].users[0].n_document;
          this.form.celular = this.form1[0].users[0].cel;
          this.form.area = funcionario;
        } else {
          // Si no existe un solicitante se pone el usuario logueado como solicitante
          this.getUser();
        }
      }
    },

    llenarTransporte() {
      this.form.tipo_vehiculo = {
        id: this.form1[0].tipo_vehiculo.id,
        nombre: this.form1[0].tipo_vehiculo.nombre,
        cantidad_pasajeros: this.form1[0].tipo_vehiculo.cantidad_pasajeros,
      };
    },

    async getUser() {
      await axios.get("/api/funcionariosFrontera/getUser").then((response) => {
        this.user = response.data[0];
      });
      this.form.user_id = await this.user.id;
      this.form.nombre = await this.user.name;
      this.form.identificacion = await this.user.n_document;
      this.form.celular = await this.user.cel;
      this.form.area = await this.user.funcionario[0] ? this.user.funcionario[0].nArea : null;
    },

    llenarpasajero(pasajeros) {
      pasajeros.forEach((pas) => {
        this.pasajeros.push({
          nombres: pas.nombres,
          numero_documento: pas.numero_documento,
          telefono: pas.telefono,
          correo: pas.correo,
          empresa_id: pas.empresa_id,
          id: pas.id,
          funcionario_id: pas.funcionario ? pas.funcionario.id : null,
        });
      });
    },

    llenarCecoAfe(ceco_afe) {
      ceco_afe.forEach((cecoafe) => {
        this.preCecosAfes.push({
          ceco_afe_id: cecoafe.ceco_afe_id,
          tipo_presupuesto: cecoafe.tipo_presupuesto,
          funcionario: cecoafe.funcionario,
          numero: cecoafe.numero,
          porcentaje: cecoafe.porcentaje,
          id: cecoafe.id,
        });
      });
    },

    llenarUsuario(users, pasajeros) {
      // Se asigna los responsables de la solicitud
      users.forEach((user) => {
        if (!this.users.find((u) => u.id === user.id)) {
          this.users.push({
            id: user.id,
            documento: user.n_document,
            funcionario_id: user.funcionario ? user.funcionario.id : null,
            cel: user.cel,
            email: user.email,
            name: user.name,
          });
        }
      });

      // Se asigna los pasajeros de la solicitud que tienen usuario y funcionario activo como responsables
      pasajeros.forEach((pasaj) => {
        if (pasaj.funcionario && pasaj.funcionario.usuario) {
          if (!this.users.find((u) => u.id === pasaj.funcionario.usuario.id)) {
            this.users.push({
              id: pasaj.funcionario.usuario.id,
              documento: pasaj.funcionario.numero_documento,
              funcionario_id: pasaj.funcionario.id,
              cel: pasaj.funcionario.celular_principal,
              email: pasaj.funcionario.email,
              name: `${pasaj.funcionario.nombres} ${pasaj.funcionario.apellidos}`,
            });
          }
        }
      });
    },

    calcularCecoAfe() {
      let count_porcentaje = 0;
      this.listasForms.cecos_afes = [];
      this.preCecosAfes.forEach((cecoafe) => {
        let pre_porcantaje = cecoafe.porcentaje / this.solicitudes.length;
        // Se busca si ya existe en el arreglo de ceco_afe
        let flag = false;
        this.cecosAfes.forEach((ca) => {
          if (
            ca.tipo_presupuesto == cecoafe.tipo_presupuesto &&
            ca.numero == cecoafe.numero
          ) {
            flag = true;
            ca.porcentaje += pre_porcantaje;
            count_porcentaje += pre_porcantaje;
          }
        });

        if (!flag) {
          this.listasForms.cecos_afes.push({
            id: cecoafe.id,
            ceco_afe_id: cecoafe.ceco_afe_id,
            tipo_presupuesto: cecoafe.tipo_presupuesto,
            funcionario: cecoafe.funcionario,
            numero: cecoafe.numero,
            porcentaje: null,
          });

          this.cecosAfes.push({
            id: cecoafe.id,
            ceco_afe_id: cecoafe.ceco_afe_id,
            tipo_presupuesto: cecoafe.tipo_presupuesto,
            funcionario: cecoafe.funcionario,
            numero: cecoafe.numero,
            porcentaje: pre_porcantaje,
          });
          count_porcentaje += pre_porcantaje;
        }
      });
      this.total_porcentaje = count_porcentaje;
    },

    async getFormItnerarioInicial() {
      this.origen_agrupamiento = `Solicitud`;
      if (this.form1.length == 1) {
        this.itinerarios.push({
          fecha_ini: this.form1[0].fecha_hora_inicio_servicio,
          fecha_fin: this.form1[0].fecha_hora_fin_servicio,
          tipo_servicio: 1,
          tep_ruta_id: null,
          tipo_vehiculo_id: this.form1[0].tipo_vehiculo_id,
          transportadora_id: null,
          direccion_origen: this.form1[0].direccion_origen,
          direccion_destino: this.form1[0].direccion_destino,
          sitio_id: this.form1[0].sitio_id,
          turno_id: null,
          tarifa_id: null,
          det_tarifa_id: null,
          cecosAfes: this.cecosAfes,
          pasajeros: this.form1[0].pasajeros,
          tipo_vehiculo: this.form1[0].tipo_vehiculo,
          ruta: [],
          sitio: null,
          tipo_operacion: null,
          area: null,
          turno: null,
          observacion: null,
        });
      } else if (this.form1.length > 1) {
        if (await this.validaItinerariosSolicitud()) {
          // Se valida si vienen de SOTT y tienen itinerarios
          // Se llena los datos de los CECO/AFE y pasajeros de los itinerarios
          this.origen_agrupamiento = `SOTT`;
          this.form1.forEach((data) => {
            data.itinerarios.forEach((itin) => {
              let cecosAfes = [];
              itin.cecos_afes.forEach((ceaf) => { cecosAfes.push(ceaf.cecos_afes); });

              let pasajeros = [];
              itin.pasajeros.forEach((pas) => {
                pasajeros.push({
                  id: pas.id,
                  nombres: pas.nombre,
                  numero_documento: pas.documento,
                });
              });

              this.itinerarios.push({
                id: itin.id,
                tep_solicitud_id: itin.tep_solicitud_id,
                fecha_ini: itin.fecha_ini,
                fecha_fin: itin.fecha_fin,
                tipo_servicio: itin.tipo_servicio,
                tep_ruta_id: itin.tep_ruta_id,
                tipo_vehiculo_id: itin.tipo_vehiculo_id,
                transportadora_id: itin.transportadora,
                direccion_origen: itin.direccion_origen,
                direccion_destino: itin.direccion_destino,
                sitio_id: itin.sitio_id,
                turno_id: itin.turno_id,
                tarifa_id: itin.tarifa_id,
                det_tarifa_id: itin.det_tarifa_id,
                cecosAfes,
                pasajeros,
                tipo_vehiculo: itin.tipo_vehiculo,
                ruta: itin.ruta,
                sitio: itin.sitio,
                tipo_operacion: data.nominacion
                  ? data.nominacion.tipo_operacion
                  : null,
                area: data.nominacion ? data.nominacion.area : null,
                turno: itin.turno,
                user: itin.user_id,
                observacion: null,
              });
            });
          });
        } else {
          // Si no se usa desde el Index de solicitudes nacionales
          // Se llena los datos de los CECO/AFE y pasajeros de las solicitudes
          let ceco_afe = [];
          let pasajero = [];
          this.form1.forEach((data) => {
            data.ceco_afe.forEach((ca) => { ceco_afe.push(ca); });
            data.pasajeros.forEach((p) => { pasajero.push(p); });
          });

          let fecha_ini = this.form.fecha_ini ? `${this.form.fecha_ini} ${this.form.hora_ini}` : ``;
          let fecha_fin = this.form.fecha_fin ? `${this.form.fecha_fin} ${this.form.hora_fin}` : ``;
          let direccion_origen = this.form.direccionOrigen ? this.form.direccionOrigen : ``;
          let direccion_destino = this.form.direccionDestino ? this.form.direccionDestino : ``;

          this.itinerarios.push({
            fecha_ini,
            fecha_fin,
            tipo_servicio: 1,
            tep_ruta_id: null,
            tipo_vehiculo_id: this.form1[0].tipo_vehiculo_id,
            transportadora_id: null,
            direccion_origen,
            direccion_destino,
            sitio_id: this.form1[0].sitio_id,
            turno_id: null,
            tarifa_id: null,
            det_tarifa_id: null,
            cecosAfes: this.cecosAfes,
            pasajeros: pasajero,
            tipo_vehiculo: this.form1[0].tipo_vehiculo,
            ruta: [],
            sitio: null,
            tipo_operacion: null,
            area: null,
            turno: null,
            observacion: null,
          });
        }
      }

      // Se llena los datos del la fecha inicial y final del transporte
      this.getFechasTransporte();
    },

    getFechasTransporte(){
      let fecha_inicial = null;
      let fecha_final = null;
      this.itinerarios.forEach(itin => {
        // Se valida la fecha inicial
        if (fecha_inicial) {
          fecha_inicial = this.validaFechaMenor(fecha_inicial, itin.fecha_ini);
        } else {
          fecha_inicial = itin.fecha_ini;
        }

        // Se valida la fecha inicial
        if (fecha_final) {
          fecha_final = this.validaFechaMayor(fecha_final, itin.fecha_fin);
        } else {
          fecha_final = itin.fecha_fin;
        }
      });

      // Se asignan los valores
      this.form.fecha_ini = fecha_inicial.slice(0, 10);
      this.form.hora_ini = fecha_inicial.slice(11, 19);
      this.form.fecha_fin = fecha_final.slice(0, 10);
      this.form.hora_fin = fecha_final.slice(11, 19);
    },

    updateFormItnerarioInicial() {
      if (this.itinerarios[0]) {
        this.itinerarios[0].fecha_ini = `${this.form.fecha_ini} ${this.form.hora_ini}`;
        this.itinerarios[0].fecha_fin = `${this.form.fecha_fin} ${this.form.hora_fin}`;
        this.itinerarios[0].direccion_origen = this.form.direccionOrigen;
        this.itinerarios[0].direccion_destino = this.form.direccionDestino;
      }
    },

    async validaItinerariosSolicitud() {
      let flag = false;
      this.form1.forEach((data) => {
        if (data.itinerarios && data.itinerarios.length > 0) {
          flag = true;
        }
      });
      return flag;
    },

    async validaSolicitudOrigen(solicitud) {
      // Se actualizan los datos del solicitante
      if (solicitud.users.length > 0) {
        let funcionario = "";
        if (
          solicitud.users[0].funcionario &&
          solicitud.users[0].funcionario.length > 0
        ) {
          funcionario = solicitud.users[0].funcionario[0].nArea;
        }
        this.form.user_id = solicitud.users[0].id;
        this.form.nombre = solicitud.users[0].name;
        this.form.identificacion = solicitud.users[0].n_document;
        this.form.celular = solicitud.users[0].cel;
        this.form.area = funcionario;
      } else {
        // Si no existe un solicitante se pone el usuario logueado como solicitante
        this.getUser();
      }

      // Se actualizan los datos del transporte
      this.form.fecha_ini = solicitud.fecha_hora_inicio_servicio.slice(0, 10);
      this.form.hora_ini = solicitud.fecha_hora_inicio_servicio.slice(11, 19);
      this.form.direccionOrigen = solicitud.direccion_origen;
      this.ruta_origen = solicitud.ruta;
      this.validaFechasForm()
      this.getNombreRuta();
      this.updateFormItnerarioInicial();
    },

    async validaSolicitudDestino(solicitud) {
      // Se actualizan los datos del transporte
      this.form.fecha_fin = solicitud.fecha_hora_fin_servicio.slice(0, 10);
      this.form.hora_fin = solicitud.fecha_hora_fin_servicio.slice(11, 19);
      this.form.direccionDestino = solicitud.direccion_destino;
      this.ruta_destino = solicitud.ruta;
      this.validaFechasForm()
      this.getNombreRuta();
      this.updateFormItnerarioInicial();
    },

    getNombreRuta() {
      if (this.ruta_origen && this.ruta_destino) {
        this.form.ruta = `${this.ruta_origen} - ${this.ruta_destino}`;
        if (this.ruta_origen == this.ruta_destino) {
          this.form.ruta = this.ruta_origen;
        }
      } else if (this.ruta_origen) {
        this.form.ruta = this.ruta_origen;
      } else if (this.ruta_destino) {
        this.form.ruta = this.ruta_destino;
      } else {
        this.form.ruta = null;
      }
    },

    getMotivo() {
      let motivo = "";
      for (let i = 0; i < this.solicitudes.length; i++) {
        const sol = this.solicitudes[i];
        const separador = this.solicitudes.length > i + 1 ? " - " : " ";
        motivo += sol.solicitud_array.motivo + separador;
      }
      this.form.motivo = motivo;
    },

    back() {
      let route =
        this.origen_agrupamiento == `Solicitud`
          ? "/Tep/SolicitudesNacionales"
          : "/Tep/Solicitudes";
      return this.$router.replace(route);
    },

    porcentajeTotal() {
      if (this.formCeAf.porcentaje) {
        this.total_porcentaje += Number(this.formCeAf.porcentaje);
        // Se valida el total del porcentaje si es mayor a 100%
        if (this.total_porcentaje > 100) {
          this.$swal({
            title: "Excedio la cantidad de ceco y afe permitida",
            text: `El limite del porcentaje es 100% de Ceco o Afe, por favor validar..`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2000,
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          });
          this.total_porcentaje -= Number(this.formCeAf.porcentaje);
          this.cecosAfes.pop();
        }
      }
    },

    cantPasajeros() {
      // Se valida la cantidad de pasajero dependiendo el tipo de vehiculo
      if (this.pasajeros.length > this.capacidad_pasajeros) {
        this.$swal({
          title: "Excedio la cantidad de pasajeros permitida",
          text:
            "La cantidad máxima de pasajeros para el tipo de vehículo " +
            " " +
            this.tipoVehiculo +
            " " +
            "son" +
            " " +
            " " +
            this.capacidad_pasajeros,
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
        });
        this.pasajeros.pop();
      }
    },

    getTipoVehiculo(tipo_vehiculos) {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 5,
            not_id: tipo_vehiculos,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipo_vehiculos = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getGerente() {
      axios
        .get("/api/admin/funcionarios/lista", {
          params: {
            gerente_area: 1,
          },
        })
        .then((response) => {
          this.listasForms.gerente = response.data;
        });
    },

    getResponsable() {
      // Se filtra que el funcionario tenga un usuario asignado
      axios
        .get("/api/admin/funcionarios/lista", {
          params: {
            con_usuario: 1,
          },
        })
        .then((response) => {
          this.listasForms.respon = response.data;
        });
    },

    getTipoServicio() {
      axios.get("/api/lista/88").then((response) => {
        this.listasForms.tipos_sevicios = response.data;
      });
    },

    nombreTipoServicio(tipo_servicio) {
      if (this.listasForms.tipos_sevicios.length > 0) {
        const servicio = this.listasForms.tipos_sevicios.find(
          (ts) => ts.numeracion === tipo_servicio
        );
        return servicio.descripcion;
      }
    },

    getTransportadoras() {
      axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [5],
          },
        })
        .then((response) => {
          this.listasForms.transportadoras = response.data;
        });
    },

    buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTipoOperacion() {
      axios.get("/api/lista/80").then((response) => {
        this.listasForms.tipo_operacion = response.data;
      });
    },

    getAreas() {
      axios.get("/api/lista/99").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    getTurnos() {
      const filtros = {
        estado: 1,
      };
      axios
        .get("/api/admin/turnos/lista", {
          params: filtros,
        })
        .then((response) => {
          this.listasForms.turnos = response.data;
        });
    },

    validaFechasForm() {
      if (this.form.fecha_ini && this.form.fecha_fin) {
        const fecha_menor = new Date(this.form.fecha_ini);
        const fecha_mayor = new Date(this.form.fecha_fin);

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.form.hora_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser mayor a la fecha fin...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.hora_fin) {
        if (this.form.hora_fin >= "20:00:00") {
          this.$swal({
            text:
              "Tenga en cuenta que el recorrido de FRONTERA va hasta las 20:00.!!",
            icon: "info",
            confirmButtonText: "Aceptar!",
          });
        }
      }
    },

    validaFechaMenor(fecha1, fecha2) {
      if (fecha1 && fecha2) {
        const fecha_1 = new Date(fecha1);
        const fecha_2 = new Date(fecha2);
        return fecha_1 <= fecha_2 ? fecha1 : fecha2;
      }
    },

    validaFechaMayor(fecha1, fecha2) {
      if (fecha1 && fecha2) {
        const fecha_1 = new Date(fecha1);
        const fecha_2 = new Date(fecha2);
        return fecha_1 > fecha_2 ? fecha1 : fecha2;
      }
    },

    cargarCeco() {
      // Se agrega a nuestra lista
      this.cecosAfes.push({
        id: +new Date(),
        funcionario: this.formCeAf.funcionario,
        tipo_presupuesto: this.formCeAf.tipo_presupuesto,
        porcentaje: this.formCeAf.porcentaje,
        numero: this.formCeAf.numero,
      });
      this.porcentajeTotal();
      // Vaciamos el formulario de añadir
      this.formCeAf.funcionario = "";
      this.formCeAf.porcentaje = "";
      this.formCeAf.tipo_presupuesto = "";
      this.formCeAf.numero = "";
    },

    verFormActualizarCecoAfe(cecosAfes_id) {
      // Antes de mostrar el formulario de actualizar, rellenamos sus campos
      this.idActualizarCA = cecosAfes_id;
      this.formCeAf2.funcionario = this.cecosAfes[cecosAfes_id].funcionario;
      this.formCeAf2.tipo_presupuesto = this.cecosAfes[
        cecosAfes_id
      ].tipo_presupuesto;
      this.formCeAf2.porcentaje = this.cecosAfes[cecosAfes_id].porcentaje;
      this.formCeAf2.numero = this.cecosAfes[cecosAfes_id].numero;
      // Mostramos el formulario
      this.formActualizarCA = true;
    },

    guardarActualizacionCecoAfe(cecosAfes_id) {
      // Se valida el valor del porcentaje
      let flag_porcentaje = this.total_porcentaje;
      flag_porcentaje -= Number(this.cecosAfes[cecosAfes_id].porcentaje);
      flag_porcentaje += Number(this.formCeAf2.porcentaje);
      if (flag_porcentaje > 100) {
        this.$swal({
          title: "Excedio la cantidad de ceco y afe permitida",
          text: `El limite del porcentaje es 100% de Ceco o Afe, por favor validar..`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
        });
        this.formCeAf2.porcentaje = this.cecosAfes[cecosAfes_id].porcentaje;
      } else {
        // Ocultamos nuestro formulario de actualizar
        this.formActualizarCA = false;
        // Se actualiza el valor del porcentaje
        this.total_porcentaje = flag_porcentaje;
        // Actualizamos los datos
        this.cecosAfes[cecosAfes_id].funcionario = this.formCeAf2.funcionario;
        this.cecosAfes[
          cecosAfes_id
        ].tipo_presupuesto = this.formCeAf2.tipo_presupuesto;
        this.cecosAfes[cecosAfes_id].porcentaje = this.formCeAf2.porcentaje;
        this.cecosAfes[cecosAfes_id].numero = this.formCeAf2.numero;
      }
    },

    borrarCecoAfe(cecosAfes_id) {
      this.$swal({
        title: "Está seguro de quitar el Ceco o Afe?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Quitar!",
      }).then((result) => {
        if (result.value) {
          this.total_porcentaje -= this.cecosAfes[cecosAfes_id].porcentaje;
          // Borramos de la lista
          this.cecosAfes.splice(cecosAfes_id, 1);
        }
      });
    },

    async datos() {
      await axios.get("/api/funcionariosFrontera/getUser").then((response) => {
        this.user = response.data[0];
        this.formPa.user_id = this.user.id;
        this.formPa.nombre = this.user.name;
        this.formPa.numero_documento = this.user.n_document;
        this.formPa.telefono = this.user.cel;
        this.formPa.correo = this.user.email;
        this.formPa.empresa_id = "FRONTERA";
      });
    },

    cargarPasajero() {
      // Se agrega a nuestra lista
      this.pasajeros.push({
        id: +new Date(),
        nombres: this.formPa.nombre,
        numero_documento: this.formPa.numero_documento,
        correo: this.formPa.correo,
        telefono: this.formPa.telefono,
        empresa_id: this.formPa.empresa_id,
      });
      this.cantPasajeros();
      // Vaciamos el formulario de añadir
      this.formPa = {
        nombre: null,
        numero_documento: null,
        correo: null,
        telefono: null,
        empresa_id: null,
      };
    },

    verFormActualizarPa(pasajeros_id) {
      // Antes de mostrar el formulario de actualizar, rellenamos sus campos
      this.idActualizarPa = pasajeros_id;
      this.formPa2.nombre = this.pasajeros[pasajeros_id].nombres;
      this.formPa2.numero_documento = this.pasajeros[
        pasajeros_id
      ].numero_documento;
      this.formPa2.telefono = this.pasajeros[pasajeros_id].telefono;
      this.formPa2.correo = this.pasajeros[pasajeros_id].correo;
      this.formPa2.empresa = this.pasajeros[pasajeros_id].empresa;
      // Mostramos el formulario
      this.formActualizarPa = true;
    },

    guardarActualizacionPa(pasajeros_id) {
      // Ocultamos nuestro formulario de actualizar
      this.formActualizarPa = false;
      // Actualizamos los datos
      this.pasajeros[pasajeros_id].nombres = this.formPa2.nombre;
      this.pasajeros[
        pasajeros_id
      ].numero_documento = this.formPa2.numero_documento;
      this.pasajeros[pasajeros_id].telefono = this.formPa2.telefono;
      this.pasajeros[pasajeros_id].correo = this.formPa2.correo;
      this.pasajeros[pasajeros_id].empresa = this.formPa2.empresa;
    },

    borrarPasajero(pasajeros_id) {
      this.$swal({
        title: "Está seguro de quitar el pasajero?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Quitar!",
      }).then((result) => {
        if (result.value) {
          // Borramos de la lista
          this.pasajeros.splice(pasajeros_id, 1);
        }
      });
    },

    async save() {
      let url = null;
      let datos = null;
      if (this.origen_agrupamiento == `Solicitud`) {
        url = "/api/tep/solicitudesNacionales";
        this.itinerarios.forEach((itin, index) => {
          this.itinerarios[index].nTipoServicio = this.nombreTipoServicio(itin.tipo_servicio);
        });

        datos = {
          id: this.form.id,
          tipo_vehiculo: this.form.tipo_vehiculo.id,
          fecha_hora_inicio_servicio: this.form.fecha_ini + " " + this.form.hora_ini,
          fecha_hora_fin_servicio: this.form.fecha_fin + " " + this.form.hora_fin,
          direccion_origen: this.form.direccionOrigen,
          direccion_destino: this.form.direccionDestino,
          cant_pasajeros: this.pasajeros.length,
          pasajeros: this.pasajeros,
          cecosAfes: this.cecosAfes,
          users: this.form.solicitudes_has_users,
          user_id: this.form.solicitudes_has_users[0],
          ruta: this.form.ruta,
          motivo: this.form.motivo,
          array_solicitudes: this.array_solicitudes,
          array_itinerarios: this.itinerarios,
        };
      } else {
        url = "/api/tep/solicitudesNacionalesSOTT";
        datos = {
          form: this.form,
          id: this.form.id,
          tep_solicitud_id: this.itinerarios[0].tep_solicitud_id,
          tipo_vehiculo: this.form.tipo_vehiculo.id,
          fecha_hora_inicio_servicio: this.form.fecha_ini + " " + this.form.hora_ini,
          fecha_hora_fin_servicio: this.form.fecha_fin + " " + this.form.hora_fin,
          direccion_origen: this.form.direccionOrigen,
          direccion_destino: this.form.direccionDestino,
          cant_pasajeros: this.pasajeros.length,
          pasajeros: this.pasajeros,
          cecosAfes: this.cecosAfes,
          users: this.form.solicitudes_has_users,
          user_id: this.form.solicitudes_has_users[0],
          ruta: this.form.ruta,
          motivo: this.form.motivo,
          array_solicitudes: this.array_solicitudes,
          array_itinerarios: this.itinerarios,
        };
      }

      if (!this.$v.form.$invalid) {
        this.cargando = true;
        await axios({
          method: "POST",
          url,
          data: datos,
        })
          .then(() => {
            this.back();
            this.$swal({
              icon: "success",
              title: "Se actualizó la solicitud exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo... " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
  },
  async mounted() {
    await this.init();
    await this.getTipoVehiculo();
    await this.getGerente();
    await this.getResponsable();
    await this.getTransportadoras();
    await this.buscarSitios();
    await this.getTipoServicio();
    await this.getTipoOperacion();
    await this.getAreas();
    await this.getTurnos();
  },
};
</script>
